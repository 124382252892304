import React, { useEffect, useState } from "react";
import { CssVarsProvider, CssBaseline, GlobalStyles } from "@mui/joy";
import { Box, IconButton, Typography, Button } from "@mui/joy";
import BadgeRoundedIcon from "@mui/icons-material/BadgeRounded";
import { getAuth, onAuthStateChanged, signOut, User } from "firebase/auth";

const TitleBar: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const auth = getAuth();

  // Listen to auth state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
        setUserEmail(user.email); // Set user's email if logged in
      } else {
        setIsLoggedIn(false);
        setUserEmail(null);
      }
    });
    return unsubscribe; // Clean up the listener on component unmount
  }, [auth]);

  // Handle logout
  const handleLogout = () => {
    signOut(auth).catch((error) => {
      console.error("Logout failed", error);
    });
  };

  return (
    <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ":root": {
            "--Form-maxWidth": "800px",
            "--Transition-duration": "0.4s",
          },
        }}
      />
      <Box
        component="header"
        sx={{
          py: 3,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ gap: 2, display: "flex", alignItems: "center" }}>
          <IconButton variant="soft" color="primary" size="sm">
            <BadgeRoundedIcon />
          </IconButton>
          <Typography level="title-lg">1Point | Admin Panel</Typography>
        </Box>

        {/* Conditional user info and logout button */}
        {isLoggedIn && (
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography level="body-md" sx={{ color: "text.secondary" }}>
              {userEmail}
            </Typography>
            <Button
              variant="soft"
              color="danger"
              size="sm"
              onClick={handleLogout}
            >
              Log Out
            </Button>
          </Box>
        )}
      </Box>
    </CssVarsProvider>
  );
};

export default TitleBar;
