import React from "react";
import { useParams } from "react-router-dom";
import Details from "../../components/Details/Details";
import { getAuth } from "firebase/auth";
import UserTableHeaders from "../../components/Headers/userTableHeaders";
import MerchantTableHeaders from "../../components/Headers/merchantTableHeaders";

const userHeaders = UserTableHeaders;
const merchantHeaders = MerchantTableHeaders;

const DetailsPage = () => {
  const { userId, merchantId } = useParams<{
    userId?: string;
    merchantId?: string;
  }>();

  const auth = getAuth();
  const [data, setData] = React.useState<{ [key: string]: any }[]>([]);
  const [error, setError] = React.useState<string | null>(null);
  const fields = userId ? userHeaders : merchantHeaders;

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchData(user);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const fetchData = async (user: any) => {
    const entity = userId ? "User" : "Merchant";
    const id = userId || merchantId;
    if (!user) {
      console.error("No user signed in");
      setError("No user signed in");
      return;
    }

    console.log("User is signed in:", user);

    try {
      const token = await user.getIdToken();
      const response = await fetch(
        `https://admin.1-point.ca/api/get${entity}/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setData(result);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(err instanceof Error ? err.message : String(err));
    }
  };

  return <Details data={data} fields={fields} />;
};
export default DetailsPage;
