import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

interface ProtectedRouteProps {
  element: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const { currentUser } = useAuth();

  // Render either the protected component or navigate to login
  return currentUser ? element : <Navigate to="/" />;
};

export default ProtectedRoute;
