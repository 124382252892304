import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/joy";

// Define props for the Table component
interface TableProps {
  title: string;
  headers: string[];
  data: Array<{ [key: string]: any }>;
  entityType: string;
}

const Table: React.FC<TableProps> = ({ title, headers, data, entityType }) => {
  const navigate = useNavigate();

  // Navigate to the detailed entity page on row click
  const handleRowClick = (id: string) => {
    navigate(`/${entityType}/${id}`);
  };

  // Navigate to the transactions page on button click
  const handleViewTransactionsClick = (id: string) => {
    navigate(`/${entityType}/${id}/transactions`);
  };

  // Truncate text to a specific length and add ellipses if necessary
  const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>{title}</h2>
      <table style={styles.table}>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header} style={styles.header}>
                {header}
              </th>
            ))}
            <th style={styles.header}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr
              key={index}
              onClick={() => handleRowClick(item.id)}
              style={styles.row}
            >
              {headers.map((header) => (
                <td key={header} style={styles.cell}>
                  {header === "is_BO"
                    ? item[header] === 1
                      ? "true"
                      : "false"
                    : truncateText(String(item[header] ?? "NULL"), 30)}
                </td>
              ))}
              <td style={styles.cell}>
                <div>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={styles.button}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent row click
                      handleViewTransactionsClick(item.id);
                    }}
                  >
                    Transactions
                  </Button>
                  <Button
                    variant="outlined"
                    color="danger"
                    style={styles.button}
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent row click
                      handleViewTransactionsClick(item.id);
                    }}
                  >
                    Deactivate
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// Style objects for dark theme
const styles = {
  container: {
    backgroundColor: "#000000",
    color: "#FFFFFF",
    padding: "0",
    margin: "0",
  },
  heading: {
    color: "#FFFFFF",
    fontSize: "1.5rem",
    marginBottom: "10px",
  },
  table: {
    width: "99%",

    borderCollapse: "collapse" as "collapse",
  },
  header: {
    backgroundColor: "#1E1E1E",
    color: "#CCCCCC",
    padding: "10px",
    textAlign: "left" as "left",
    borderBottom: "1px solid #333333",
  },
  row: {
    cursor: "pointer",
    backgroundColor: "#1E1E1E",
    transition: "background-color 0.3s",
  },
  cell: {
    alignItems: "center",
    padding: "10px",
    color: "#DDDDDD",
    borderBottom: "1px solid #333333",
  },
  button: {
    minWidth: "100px",
    textAlign: "center" as "center",
    justifyContent: "center" as "center",
    padding: "10px",
    margin: "5px",
  },
};

export default Table;
