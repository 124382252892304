const UserTableHeaders = [
  "id",
  "firstName",
  "lastName",
  "email",
  "phoneNumber",
  "ipAddress",
  "isAdmin",
  "isBO",
  "merchantID",
  "status",
  "createdAt",
  "updatedAt",
];

export default UserTableHeaders;
