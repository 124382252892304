// Dashboard.tsx
import * as React from "react";
import Table from "../../components/Table/Table";
import { getAuth } from "firebase/auth";
import UserTableHeaders from "../../components/Headers/userTableHeaders";
import MerchantTableHeaders from "../../components/Headers/merchantTableHeaders";

const userHeaders = UserTableHeaders;
const merchantHeaders = MerchantTableHeaders;

// Sample static data for the tables
let users: { [key: string]: any }[] = [];

const Dashboard = () => {
  const auth = getAuth();
  const [data, setData] = React.useState<{ [key: string]: any }[]>([]);
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchData(user);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const fetchData = async (user: any) => {
    if (!user) {
      console.error("No user signed in");
      setError("No user signed in");
      return;
    }

    console.log("User is signed in:", user);

    try {
      const token = await user.getIdToken();
      const response = await fetch("https://admin.1-point.ca/api/getUsers", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setData(result);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(err instanceof Error ? err.message : String(err));
    }
  };
  return (
    <div>
      <div>
        <Table
          entityType="user"
          title="User Table"
          headers={userHeaders}
          data={data}
        />
      </div>
      <div></div>
    </div>
  );
};

export default Dashboard;
