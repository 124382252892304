// firebaseConfig.ts
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBzcZo-OJGLw8IQupUTaB5G4YMWmAVfMC0",
  authDomain: "point-admin.firebaseapp.com",
  projectId: "point-admin",
  storageBucket: "point-admin.firebasestorage.app",
  messagingSenderId: "114046315541",
  appId: "1:114046315541:web:57ec358caffe48e093321c"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };