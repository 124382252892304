import * as React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "../../contexts/AuthContext";
import Login from "../LoginPage/Login";
import Dashboard from "../Dashboard/Dashboard"; // Ensure Dashboard component is imported
import TitleBar from "../../components/TitleBar/TitleBar";
import ProtectedRoute from "./ProtectedRoute";
import DetailsPage from "../DetailsPage/DetailsPage";

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <TitleBar />
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="/dashboard"
              element={<ProtectedRoute element={<Dashboard />} />}
            />
            <Route
              path="/user/:userId"
              element={<ProtectedRoute element={<DetailsPage />} />}
            />
            <Route
              path="/merchant/:merchantId"
              element={<ProtectedRoute element={<DetailsPage />} />}
            />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
